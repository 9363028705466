.cards {
  @extend %row;

  margin-left: rem(-20);
  margin-right: rem(-20);

  @include lg {
    margin-left: rem(-15);
    margin-right: rem(-15); }

  &__item {
    width: percentage(1 / 3);
    padding-left: rem(20);
    padding-right: rem(20);
    margin-bottom: rem(40);

    @include lg {
      padding-left: rem(15);
      padding-right: rem(15);
      margin-bottom: rem(30); }

    @include md {
      width: 50%; }

    @include sm {
      width: 100%;
      &:nth-child(even) {
        .link-card {
          flex-direction: row-reverse;

          &__footer {
            text-align: left; } } } }

    .link-card {
      // height: 100%
      @include smmin {
        height: rem(480); } } } }

.card {
  $this: &;

  display: block;

  @include hover-focus {
    #{$this}__content {
      @extend %visible;

      #{$this}__title,
      #{$this}__text {
        span {
          transform: translate(0, 0);
          opacity: 1; } } }

    #{$this}__img {
      @extend %hidden; } }

  &__img-wrap {
    display: block;
    position: relative;
    background-color: $lighten;
    padding-top: percentage(415 / 442);

    overflow: hidden; }

  &__img {
    @extend %vcenter;

    max-height: 80%;

    @include ready {
      @include tr(opacity .4s, visibility .4s); } }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: rem(23) 0; }

  &__price,
  &__title {
    @extend %text-title;
    margin-bottom: 0;
    font-weight: 600; }

  &__price {
    white-space: nowrap; }

  &__title {
    display: block;
    color: inherit; }

  &__text {
    display: block; }

  &__content {
    @extend %coverdiv;
    @extend %hidden;
    @extend %flex-center;

    flex-direction: column;
    background-color: $lighten;
    padding: rem(45) rem(10) rem(45);
    text-align: center;
    @include ready {
      @include tr(opacity .4s, visibility .4s); }

    @include xl {
      padding: rem(25) rem(10) rem(25); }

    #{$this}__title,
    #{$this}__text {
      overflow: hidden;
      span {
        display: block;
        transform: translate(0, 30px);
        @include ready {
          transition-property: opacity, transform;
          transition-duration: .3s, .4s; } } }

    #{$this}__title {
      margin-bottom: rem(15);
      @include xl {
        margin-bottom: rem(8); } }

    #{$this}__text {
      span {
        transition-delay: .05s; } } } }

