.about-slider-content {
  width: 100%;

  &__video {
    display: block;
    margin-left: auto;
    width: 100%;
    max-width: calc(50% - #{rem(30)});
    padding-top: 30%;

    @include xl {
      padding-top: 23%;
      max-width: percentage(242 / 659); }

    @include xs {
      margin-right: 0;
      max-width: percentage(167 / ($base-mobile - 80));
      padding-top: 32%; } }

  &__block {
    @include md {
      margin-left: auto;
      margin-right: auto; }

    @include notlast {
      margin-bottom: rem(80);
      @include xs {
        margin-bottom: rem(40); } }

    &:first-child {
      @include xl {
        max-width: rem(412); } } } }

.prev,
.next {
  display: inline-flex;
  align-items: center;
  min-height: rem(30);
  @include tr(color .3s);

  @include xs {
    font-size: 0; }

  .icon {
    margin-right: rem(20);
    font-size: rem(14);
    color: $light;
    font-size: rem(8);
    @include xs {
      margin-right: 0; } }

  @include hover {
    color: $light; }

  &.swiper-button-disabled {
    opacity: .5;
    pointer-events: none; } }

.next {
  .icon {
    transform: scaleX(-1);
    margin-right: 0;
    margin-left: rem(20);

    @include xs {
      margin-left: 0; } } }

.slider {
  $this: &;

  &__wrap {
    position: relative;
    * {
      box-sizing: border-box; } }

  &__controls {
    display: flex;
    align-items: center; }

  &__prev {
    margin-right: rem(49);

    @include sm {
      margin-right: rem(44); } }

  &__pagination {
    text-align: center;

    .swiper-pagination-bullet {
      display: inline-block;

      width: rem(10);
      height: rem(10);

      background-color: $light;

      margin-left: rem(12);
      margin-right: rem(12);

      cursor: pointer;

      &.swiper-pagination-bullet-active {
        background-color: $black; } } }

  &--main,
  &--about {
    &__wrap {
      // TODO: Uncomment, if need to add custom transition.

      // .swiper-slide
      //   position: absolute
      //   z-index: 0
      //   top: 0
      //   left: 0

      //   &:first-child
      //     z-index: 1

      .slider {
        &__img {
          width: percentage(5 / 12);
          padding-left: rem(15);
          .swiper-container,
          .swiper-wrapper,
          .swiper-slide,
          .image {
            height: 100%; } }

        &__controls {
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: percentage(413 / $base-container);

          @include xxxl {
            left: 20%; }

          @include lg {
            left: 50%;
            transform: translate(-50%, 0); }

          @include xs {
            bottom: auto;
            left: 50%;
            right: auto;
            transform: translate(-50%, 0);
            width: 100%;
            max-width: rem(334);
            justify-content: space-between; } }

        &__img {
          img {
            @extend %coverimage; } } } } }

  &--about {
    &__wrap {
      @include lg {
        margin-bottom: rem(-50); }
      @include md {
        margin-bottom: rem(-80); }

      .slider {
        &__img {
          padding-left: 0;
          padding-right: rem(15); }

        &__content-wrap {
          padding-right: 0;
          padding-left: rem(15) + rem(50); }

        &__controls {
          left: calc(#{percentage(5 / 12)} + #{rem(65)});
          @include lg {
            left: 50%; }
          @include xs {
            left: 0;
            transform: none; } } } } }

  &--recommend {
    &__wrap {
      .slider {
        &__controls {
          position: absolute;
          z-index: 2;
          left: 50%;
          bottom: calc(100% + #{rem(35)});
          transform: translate(-50%, 0);

          width: 100%;
          max-width: rem($base-container);
          padding-right: $base-gap;

          justify-content: flex-end;

          @include lg {
            padding-right: $container-gap; }

          @include md {
            position: static;
            padding-right: 0;
            transform: none;
            justify-content: space-between; } } } } }

  &--gallery {}

  &--image {
    &__wrap {
      .slider {
        .swiper-slide {
          position: relative;
          @include md {
            padding-top: percentage(480 / 600); }
          img {
            @extend %vcenter;

            max-height: percentage(671 / 858);

            @include md {
              max-height: percentage(325 / 416); } } }


        &__pagination {
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          bottom: rem(40);

          @include md {
            position: relative;
            bottom: auto;
            padding-top: rem(25); } } } } } }



