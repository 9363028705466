.catalog {
  @extend %row;

  @include lgmin {
    &__top-line {
      order: 0; }
    &__aside {
      order: 1; }
    &__body {
      order: 2; } }

  &__aside {
    width: calc(#{percentage(2 / 12)} + #{rem(30)});

    @include lg {
      width: 100%;
      padding-top: 0; } }

  &__body {
    width: calc(#{percentage(9 / 12)} + #{rem(30)});
    margin-left: calc(#{percentage(1 / 12)} - #{rem(60)});
    @include lg {
      width: 100%;
      margin-left: 0;
      margin-top: rem(40); } }

  &__top-line {
    @extend %row;

    width: 100%;


    align-items: flex-end;
    justify-content: space-between;

    padding: rem(20) 0;
    padding-left: calc(#{percentage(3 / 12)} - #{rem(30)});

    @include lg {
      display: block;
      padding: 0;

      // padding-left: rem(40)
      // padding-right: rem(40)

      // width: calc(50% - #{rem(10)})
      // margin-left: auto
 }      // margin-top: rem(-49) // alignment filters and sort selects

    .filter-block {
      @include lgmin {
        min-width: rem(275); }

      &__panel {
        @include lg {
          // width: calc(200% + #{rem(20)}) !important
          left: auto !important;
          right: 0 !important; } } } }

  &__all {
    font-size: em(15);
    color: $light;
    letter-spacing: 0.02em;

    @include lg {
      display: none; } } }
