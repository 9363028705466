.s-hero {
  $this: &;

  @extend %section-padding;

  &__title {
    text-align: right;
    max-width: rem(1090);
    margin-left: auto; }

  &__top {
    position: relative;
    margin-bottom: rem(30);

    @include md {
      margin-bottom: rem(20); } }

  &__social {
    position: absolute;
    bottom: 0;
    left: 0;

    transform: rotate(-90deg) translate(0, 100%);
    transform-origin: bottom left;

    @include md {
      display: none; } }

  &__inner {
    position: relative; }

  &__right {
    img {
      @extend %coverimage; } }

  &__left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: rem(-25);
    width: vw(1358);

    @include xxlmin {
      max-width: rem(1620); }

    @include xl {
      width: vw(933, $base-tablet-lg); }

    @include lg {
      position: relative;
      width: auto;

      padding-top: percentage(262 / 374);

      left: auto;
      margin-left: rem(-40);
      margin-bottom: rem(22); }

    img {
      @extend %coverimage; }

    .image {
      @extend %coverdiv; } }

  &__text-block {
    @include lg {
      margin-bottom: rem(36); } }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-height: vw(664);
    width: 100%;
    max-width: percentage(340 / $base-container);
    margin-left: auto;
    text-align: right;

    @include xxxlmin {
      max-width: rem(340); }

    @include xl {
      min-height: vw(505, $base-tablet-lg);
      max-width: percentage(253 / $base-tablet-lg); }

    @include lg {
      max-width: rem(333);
      min-height: 0; } }

  &__img-lg {
    width: 100%;
    padding-top: percentage(664 / 1623);

    @include xl {
      padding-top: percentage(505 / 1200); }

    @include md {
      width: calc(100% + #{rem(110)});

      margin-left: rem(-55);
      margin-right: rem(-55);

      margin-bottom: rem(25);
      padding-top: percentage((262 + 65) / $base-mobile); } }

  &--blog {
    padding-bottom: 0;

    #{$this}__top {
      @extend %row;

      justify-content: space-between;
      align-items: flex-end;

      margin-bottom: rem(90);

      @include xl {
        margin-bottom: rem(50); } }

    #{$this}__title {
      width: percentage(7 / 12);
      margin-left: percentage(1 / 12);
      margin-bottom: 0;
      text-align: left;
      @include md {
        margin-left: 0;
        margin-bottom: rem(25);
        width: 80%; } }

    #{$this}__top-right {
      width: 100%;
      max-width: rem(275);
      @include css-lock(10, 27, 479, $base-desktop, margin-bottom);
      @include md {
        margin-bottom: 0; }
      @include xs {
        max-width: rem(160); } } }

  &--home {
    #{$this}__top {
      @include xxxlmin {
        width: calc(#{vw(1358)} - #{$main-gap});
        max-width: rem(1620) - $main-gap; }

      > .container {
        position: relative; } }

    #{$this}__title {
      margin-right: calc(100vw - #{vw(1358)} - #{rem(25)});

      @include xxxlmin {
        margin-right: 0; }


      @include xl {
        margin-right: calc(100vw - #{vw(933, $base-tablet-lg)} - #{rem(25)}); }

      @include lg {
        margin-right: 0; } } }

  &--about {
    #{$this}__title {
      @include mdmin {
        order: 0; } }
    #{$this}__text-block {
      @include mdmin {
        order: 1; } }
    #{$this}__social-wrap {
      @include mdmin {
        order: 2; } }
    #{$this}__img-lg {
      @include mdmin {
        order: 3; } }

    #{$this}__inner {
      @extend %row;
      justify-content: space-between;
      align-items: flex-end; }

    #{$this}__social-wrap {
      width: 100%;
      position: relative;
      top: rem(-70); }

    #{$this}__title {
      width: percentage(7 / 12);
      margin-left: percentage(1 / 12);
      text-align: left;
      margin-bottom: rem(39);
      @include xl {
        margin-bottom: rem(34); }

      @include md {
        margin-left: 0;
        margin-bottom: rem(25);
        width: 80%; } }

    #{$this}__text-block {
      width: percentage(4 / 12);
      margin-bottom: rem(40 + 20);

      @include md {
        margin-bottom: 0;
        width: 100%; }

      > .text-block {
        max-width: rem(412);
        margin-left: auto;
        @include md {
          margin-left: 0; } } } } }


