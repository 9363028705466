.section {
  @extend %section-padding;

  &__title {
    margin-bottom: rem(82);
    @include xl {
      margin-bottom: rem(57); }
    @include sm {
      margin-bottom: rem(40); }
    @include xs {
      margin-bottom: rem(20); } }

  &__btn {
    margin-top: rem(60);

    @include sm {
      margin-top: rem(40); }

    .fancy-link {
      margin-left: calc(50% - #{rem(20)});
      transform: translate(-100%, 0);

      @include xl {
        margin-left: calc(50% - #{rem(15)}); }

      @include md {
        transform: none;
        margin-left: rem(120); }

      @include sm {
        margin-left: rem(100); } } } }


.section-slide {
  $this: &;

  @extend %clr;
  background-color: $white;

  &__content,
  &__title {
    padding-right: percentage(85 / $base-container);
    padding-left: percentage(1 / 12);

    @include xl {
      padding-right: percentage(80 / $base-tablet-lg); }

    @include lg {
      padding-left: 0;
      padding-right: 0; } }

  &__title {
    @extend .section__title;

    float: left;

    width: percentage(($base-container - 660)/ $base-container);
    margin-top: rem(100);

    @include xl {
      margin-top: rem(65); }

    @include lg {
      width: auto;
      float: none;
      max-width: none;
      margin-top: 0; } }

  &__img {
    float: right;
    width: percentage(660 / $base-container);
    height: vw(869);
    max-height: rem(869);

    @include xl {
      width: percentage(483 / ($base-tablet-lg - 80));
      height: vw(637, $base-tablet-lg);
      max-height: rem(637); }

    @include lg {
      float: none;
      margin-bottom: rem(25);
      margin-left: auto;
      margin-right: auto;

      width: 100%;
      max-width: rem(334);
      height: rem(440); } }

  &__content {
    @extend %row;

    float: left;
    width: percentage(($base-container - 660)/ $base-container);

    margin-bottom: rem(100);

    @include xl {
      width: percentage(($base-tablet-lg - 80 - 483) / ($base-tablet-lg - 80)); }

    @include lg {
      width: auto;
      float: none;
      margin-bottom: rem(50); }

    @include xs {
      margin-top: rem(65, $base-fz-sm); } }

  &__content-left {
    width: 35%;
    padding-right: rem(15);

    @include xs {
      width: 100%;
      padding-right: 0;
      order: 1;
      text-align: left; } }

  &__content-right {
    width: 65%;
    padding-left: rem(15);

    @include xs {
      width: 100%;
      max-width: rem(333);
      margin-bottom: rem(40);
      padding-left: 0;
      margin-left: auto;
      margin-right: auto; } }

  &--right {
    #{$this}__img {
      float: left;

      @include lg {
        width: percentage(483 / ($base-tablet-lg - 80));
        max-width: none;
        height: vw(637, $base-tablet-lg); }

      @include md {
        float: none;
        width: 100%;
        max-width: rem(334);
        height: rem(440); } }

    #{$this}__title,
    #{$this}__content {
      float: right;
      padding-left: percentage(77 / $base-container);
      padding-right: 0;

      @include md {
        padding-left: 0;
        float: none; } }

    #{$this}__title {
      margin-top: 0;
      @include xl {
        float: none;
        padding-left: 0; } }

    #{$this}__content {
      margin-bottom: 0;
      @include xl {
        padding-left: percentage(58 / ($base-tablet-lg - 80)); }
      @include lg {
        width: percentage(($base-tablet-lg - 80 - 483) / ($base-tablet-lg - 80)); }
      @include md {
        margin-bottom: rem(50);
        padding-left: 0;
        width: 100%; }
      @include xs {
        margin-bottom: rem(80); } } } }

.section-video {
  position: relative;
  video,
  img {
    @extend %coverimage;
    position: absolute;
    top: 0;
    left: 0; }

  &__btn {
    @extend %vcenter;
    position: absolute !important; } }

.s-error {
  text-align: center;

  &__content {
    position: relative; }

  &__text,
  &__title {
    max-width: rem(490);
    margin-left: auto;
    margin-right: auto; }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    min-height: calc(100vh - #{$header-height}); }

  &__img {
    margin-bottom: rem(76);
    @include sm {
      margin-bottom: rem(48); } }

  &__title {
    margin-bottom: rem(25);
    @include sm {
      margin-bottom: rem(19, $base-fz-sm); } }

  &__text {
    @extend %text;

    @include sm {
      margin-bottom: rem(45, $base-fz-sm); } }

  &__nav {
    position: absolute;
    top: calc(100% + #{rem(142)});
    left: 0;

    width: 100%;

    @include rh(1000) {
      position: static;
      margin-top: rem(142); }

    @include sm {
      margin-top: 0; } } }

.s-slider {
  @extend %section-padding;

  &__block {
    @include notlast {
      margin-bottom: rem(220);
      @include xl {
        margin-bottom: rem(150); }
      @include lg {
        margin-bottom: rem(100); }
      @include md {
        margin-bottom: rem(50); } } } }

.s-info {
  @extend %section-padding;
  @extend %full-section;

  position: relative;
  overflow: hidden;

  &__bg-wrap {
    @include md {
      position: relative; } }

  &__bg {
    @extend %coverimage;

    position: absolute;
    z-index: 0;
    top: 0;
    left: -$main-gap;
    width: percentage(1360 / ($base-desktop - 50));

    @include xl {
      width: percentage(933 / ($base-tablet-lg - 50)); }

    @include md {
      position: relative;
      left: auto;
      margin-bottom: rem(20);
      margin-left: -$base-gap;

      width: vw(376, $base-mobile);
      height: vw(262, $base-mobile);
      max-width: none;

      object-fit: cover; } }

  &__title,
  &__content-block {
    position: relative;
    z-index: 1; }

  &__title {
    @extend .section__title;
    text-align: right; }

  &__inner {
    padding-top: rem(118);
    padding-bottom: rem(115);
    position: relative;

    @include xl {
      padding-top: rem(74);
      padding-bottom: rem(74); }

    @include md {
      padding-top: 0;
      padding-bottom: 0; } }

  &__images {
    @extend %row;

    position: absolute;
    z-index: 0;
    top: rem(153);
    right: -$main-gap;

    @include xl {
      top: rem(118);
      right: calc(#{vw(-144, $base-tablet-lg)} - #{$main-gap}); }

    @include md {
      top: 15%; }

    @include sm {
      right: rem(-40, $base-fz-sm); }

    img,
    .image {
      object-fit: cover;
      width: vw(427, $base-desktop);
      height: vw(563, $base-desktop);

      @include xxxlmin {
        width: rem(427);
        height: rem(563); }

      @include xl {
        width: vw(304, $base-tablet-lg);
        height: vw(400, $base-tablet-lg); }

      @include md {
        width: vw(136, $base-mobile);
        height: vw(180, $base-mobile); } }

    li {
      @include notlast {
        margin-right: rem(40);
        @include xl {
          margin-right: rem(30); }
        @include lg {
          margin-right: rem(20); } } } }

  &__content {
    max-width: percentage(1208 / $base-container);
    padding-right: rem(90);
    padding-left: percentage(1 / 12);

    @include md {
      padding-left: 0;
      padding-right: 0;
      max-width: none; } }

  &__content-block {
    @extend %row;

    @include lg {
      display: block; }

    @include md {
      text-align: right; }

    .text-block {
      max-width: rem(420);
      margin-right: rem(52);

      @include xl {
        margin-right: 0; }

      @include lg {
        margin-bottom: rem(40); }

      @include md {
        margin-right: 0;
        margin-left: auto; } } } }

.s-blog {
  @extend %section-padding;

  &__title {
    margin-bottom: rem(80);
    max-width: rem(620);
    margin-left: auto;

    @include lg {
      margin-left: 0;
      margin-bottom: rem(40); }
    @include md {
      margin-bottom: rem(25); } }

  &__subttl {
    @extend %text-title;

    max-width: rem(403);
    margin-bottom: rem(20);
    margin-left: auto;

    @include sm {
      max-width: percentage(237 / 334); } }

  &__inner {
    @extend %row; }

  &__left,
  &__right {
    display: flex;
    flex-direction: column;

    width: percentage(1 / 2);
    min-height: vw(1300);

    @include rmin($base-container + 80) {
      min-height: rem(1180); }

    @include md {
      width: 100%;
      min-height: 0; } }

  &__left {
    padding-right: rem(15);

    @include md {
      order: 1;
      padding-right: 0; } }

  &__right {
    padding-left: rem(15);

    @include md {
      padding-left: 0;
      margin-bottom: rem(30); }

    > .post {
      margin-top: auto; } }

  &__link {
    margin-top: auto;
    padding-left: percentage(1.1 / 6);

    margin-bottom: rem(105);

    @include xl {
      margin-bottom: rem(80);
      padding-left: rem(150); }

    @include lg {
      padding-left: rem(120);
      margin-bottom: rem(75); }

    @include md {
      margin-bottom: 0;
      margin-top: rem(55); }

    @include sm {
      padding-left: rem(100); } } }

.s-recommend {
  @extend %full-section;
  @extend %section-padding;

  overflow: hidden;

  &__top-line {
    margin-bottom: rem(38);
    @include lgmin {
      padding-left: percentage(1 / 12); }
    @include lg {
      margin-bottom: rem(22); } }

  &__title {
    margin-bottom: rem(82);
    width: percentage(7 / 12);

    @include xl {
      margin-bottom: rem(65); }
    @include md {
      width: auto;
      margin-bottom: rem(17); } }

  &__subttl {
    @extend %text-title;

    margin-bottom: 0;

    @include md {
      margin-left: auto; } }

  &__slider {
    margin-right: -$main-gap;

    @include lg {
      padding-left: $container-gap;
      padding-right: $container-gap;
      margin-right: 0; }
    @include xs {
      margin-right: 0; } } }

.s-instagram {
  @extend %full-section;

  position: relative;
  padding-top: rem(125);
  padding-bottom: rem(125);

  padding-left: 0;
  padding-right: 0;

  overflow: hidden;

  @include xl {
    padding-top: rem(95);
    padding-bottom: rem(95); }

  @include lg {
    padding-top: rem(80);
    padding-bottom: rem(80); }

  @include md {
    padding-top: rem(65);
    padding-bottom: rem(65); }

  &__top {
    @extend %row;

    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: rem(50);

    @include md {
      display: block;
      text-align: center;
      margin-bottom: rem(24); } }

  &__title {
    @include md {
      margin-bottom: rem(5); } }

  &__link {
    @extend .title;
    @extend .title--h4;
    @extend .title--ttn;

    @include css-lock(5, 20, 479, $base-desktop, margin-bottom); }

  &__bg {
    @extend %coverimage;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }

  &__inner {
    position: relative;
    z-index: 1; } }

.s-about-slider {
  @extend %section-padding; }

.s-about-brands {
  @extend %section-padding;

  .section__title {
    margin-left: percentage(1 / 12);
    max-width: percentage(1147 / $base-container);

    @include xxxl {
      margin-left: 0; } }

  &__content {
    @extend %row;

    position: relative;

    @include xxxl {
      margin-left: rem(-15);
      margin-right: rem(-15); }

    @include md {
      padding-top: rem(420); } }

  &__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: percentage(3 / 12);
    margin-left: percentage(1 / 12);

    @include xxxl {
      width: percentage(4 / 12);
      margin-left: 0;

      padding-left: rem(15);
      padding-right: rem(15); }

    @include md {
      width: 100%; }

    .text-block {
      @include notlast {
        margin-bottom: rem(30);
        @include md {
          margin-bottom: rem(22); } } }

    .two-images {
      @include md {
        position: absolute;
        z-index: 1;

        left: 0;
        top: rem(250);

        width: 100%;
        max-width: rem(228); } }

    &:nth-child(2) {
      @include md {
        margin-bottom: rem(22); } } }

  &__img {
    display: block;

    width: 100%;
    max-width: rem(266);

    margin-top: rem(-100);
    margin-bottom: rem(30);

    @include xxxl {
      margin-top: 0; }

    @include xl {
      max-width: rem(190); }

    @include md {
      position: absolute;
      z-index: 0;
      top: 0;
      left: rem(97);

      margin-bottom: 0;
      max-width: rem(236);
      width: 100%;
      height: rem(311);

      object-fit: contain; } } }

.s-about-awards {
  @extend %section-padding;

  &__title {
    @include md {
      order: 0; } }
  &__img {
    @include md {
      order: 1; } }
  &__text-block {
    @include md {
      order: 2; } }

  &__inner {
    @extend %clr;

    @include md {
      display: flex;
      flex-wrap: wrap; } }

  &__img {
    float: right;
    padding-top: 53.7%;
    width: percentage(660 / $base-container);

    @include xl {
      width: percentage(483 / ($base-tablet-lg - 80));
      padding-top: 53.1%; }

    @include md {
      float: none;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: rem(45);

      max-width: rem(334);
      height: rem(440);
      width: 100%; } }


  &__text-block,
  &__title {
    width: percentage(($base-container - 660) / $base-container);
    padding-right: rem(30);

    text-align: center;

    @include md {
      width: 100%;
      padding-right: 0; }

    @include xxs {
      text-align: left; } }

  &__title {
    @extend .section__title;

    padding-top: rem(40);

    @include xl {
      padding-top: 0;
      margin-bottom: rem(25); }

    @include md {
      padding-top: 0; }

    @include xs {
      margin-bottom: rem(20); }

    > .title {
      max-width: percentage(750 / 933);
      margin-left: auto;
      margin-right: auto;

      @include md {
        max-width: none;
        margin-left: 0;
        margin-right: 0; } } }

  &__text-block {
    @include xxs {
      margin-left: auto;
      max-width: rem(240); }

    .text-block {
      max-width: rem(415);
      margin-left: auto;
      margin-right: auto;
      img {
        @include xl {
          max-width: rem(176); }
        @include xxs {
          max-width: rem(158); } } } } }

.s-article {
  padding-top: rem(158);
  padding-bottom: rem(41);

  @include xl {
    padding-top: rem(75); }
  @include md {
    padding-top: rem(60); }
  @include xs {
    padding-top: rem(45); }

  &__top {
    text-align: center;
    margin-bottom: rem(45);
    @include sm {
      margin-bottom: rem(30, $base-fz-sm); }

    + img {
      display: block;
      width: 100%;
      margin-bottom: rem(69);

      @include xl {
        margin-bottom: rem(55); }

      @include md {
        display: block;
        width: calc(100% + #{rem(80)});
        max-width: calc(100% + #{rem(80)});

        margin-left: rem(-40);
        margin-right: rem(-40);

        margin-bottom: rem(30); }

      @include sm {
        margin-bottom: rem(20); } } }

  &__upttl {
    @extend %text-title;

    margin-bottom: rem(43);

    @include lg {
      margin-bottom: rem(30); }
    @include md {
      margin-bottom: rem(20); } }

  &__title {
    margin-bottom: rem(65);
    @include lg {
      margin-bottom: rem(50); }
    @include md {
      margin-bottom: rem(30); }
    @include sm {
      margin-bottom: rem(20); } }

  &__date {
    @extend %text-title;
    font-weight: 400;
    color: inherit; } }

.s-contacts {
  $form-width:       percentage(584 / $base-container);
  $form-left-gap:    percentage(($base-container - 584 - 655 - 275) / $base-container);
  $content-width:    percentage(655 / $base-container);
  $content-left-gap: percentage(275 / $base-container);

  min-height: calc(100vh - #{$header-height});
  display: flex;
  justify-content: center;
  flex-direction: column;

  &__inner {
    @extend %row;

    padding-top: rem(90);
    padding-bottom: rem(90);

    @include md {
      padding-top: rem(50);
      padding-bottom: 0; } }

  &__form {
    margin-left: $form-left-gap;
    width: $form-width;
    @include xxxl {
      margin-left: 0;
      width: $form-width + $form-left-gap; }

    @include lg {
      width: 50%;
      padding-right: rem(15); }

    @include md {
      order: 1; } }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: $content-width;
    margin-left: $content-left-gap;

    @include xxxl {
      margin-left: 0;
      width: $content-width + $content-left-gap;
      padding-left: 10%; }

    @include lg {
      width: 50%;
      padding-left: rem(15); }

    @include md {
      margin-bottom: rem(90); } }

  &__form,
  &__content {
    @include md {
      width: 100%;
      padding-left: 0;
      padding-right: 0; } }

  &__text {
    max-width: rem(522);
    line-height: (29 / 14);

    a {
      @include hover-focus {
        text-decoration: underline; } } }

  &__contacts {
    height: 100%; } }


.s-catalog {
  @extend %section-padding;

  .section__title {
    max-width: rem(860);

    @include lgmin {
      margin-left: calc(#{percentage(3 / 12)} - #{rem(30)}); } } }

.s-search {
  @extend %section-padding;

  &__title {
    @extend .section__title;

    margin-left: percentage(108 / $base-container);

    > p {
      @extend %text-title;
      font-weight: 600; } } }

