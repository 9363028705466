.aside {
  &__block {
    margin-bottom: rem(75);
    @include lg {
      margin-bottom: rem(10);

      @include notfirst {
        width: calc(50% - #{rem(10)});

        // .dropdown__panel
 } }        //   width: calc(200% + #{rem(20)})

    &.dropdown--open {
      .dropdown__panel {
        visibility: visible !important;
        opacity: 1 !important; } }

    &:first-child {
      @include lg {
        // margin-left: rem(-40)
 }        // margin-right: rem(-40)

      &.dropdown {
        @include lg {
          border-top: 1px solid currentColor;


          // .dropdown__title
          //   padding-left: rem(40)
          //   padding-right: rem(40)
          //   &::before
          //     right: rem(40)

          // .dropdown__panel
          //   padding-left: rem(40)
          //   padding-right: rem(40)
 } } } } }          //   padding-bottom: rem(40)
