.footer {
  padding-top: rem(225);
  padding-bottom: rem(125);

  @include xxxl {
    padding-bottom: rem(100); }

  @include xxl {
    padding-bottom: rem(60); }

  @include xl {
    padding-top: rem(125);
    padding-bottom: rem(40); }

  @include lg {
    padding-bottom: rem(100);
    padding-top: rem(100); }

  @include sm {
    padding-top: rem(90);
    padding-bottom: rem(90); }

  @include xs {
    padding-bottom: rem(20); }

  &__top,
  &__bottom {
    @extend %row; }

  &__top {
    margin-bottom: rem(65);
    position: relative;
    @include xl {
      margin-bottom: rem(45); } }

  &__bottom {
    align-items: flex-end; }

  &__left,
  &__social {
    width: percentage(3 / 12); }

  &__middle,
  &__copy {
    width: percentage(5 / 12);
    padding-left: rem(15); }

  &__social {
    @include md {
      width: 100%; } }

  &__copy {
    @include md {
      padding-left: 0; } }

  &__left {
    @include lg {
      width: 100%;
      margin-bottom: rem(52); } }

  &__middle {
    @include lg {
      padding-left: 0;
      width: 50%; }

    @include md {
      width: 100%;
      margin-bottom: rem(52) - rem(25); } } // minus nav item margin

  &__right {
    width: percentage(4 / 12);
    position: relative;
    @include lg {
      width: 50%;
      position: static; }
    @include md {
      width: 100%; } }

  &__lang {
    position: absolute;
    bottom: 0;
    right: rem(-10); }

  &__auth {
    margin-left: auto; }

  &__block {
    position: relative;
    @include notlast {
      margin-bottom: rem(55); } }

  &__scroll-top {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0; } }
