html,
body {
  height: 100%; }

html {
  font-size: $base-fz + px;
  line-height: 1.4;
  color: $c-text;
  @include reg;
  @include sm {
    font-size: $base-fz-sm + px; } }

body {
  background-color: $white;
  min-width: 375px; }

.icon {
  display: inline-block;
  fill: currentColor;
  font-size: inherit; }

.lazy {
  $this: &;
  &--loaded:not(#{$this}--no-fade-in) {
    animation: fadeIn 1s; } }

.js-animated-title,
.js-animated-section {
  .is-ready & {
    opacity: 0; } }

.image {
  position: relative;
  canvas {
    position: absolute;
    top: 0;
    left: 0; } }

.logo {
  display: inline-block;
  font-size: 0;
  width: rem(66);
  @include lg {
    width: rem(56); } }

.auth {
  display: inline-block;
  font-size: 0; }

.main {
  &__breadcrumbs {
    margin-top: rem(100);
    @include xl {
      margin-top: rem(60); }
    @include sm {
      margin-top: rem(35, $base-fz-sm);
      margin-left: -$main-gap;
      margin-right: -$main-gap; } } }

.container {
  width: 100%;
  max-width: rem($base-container) + ($container-gap * 2);

  margin-left: auto;
  margin-right: auto;

  padding-left: $container-gap;
  padding-right: $container-gap; }

.lang {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: rgba($c-text, .5);

  &__item {
    padding-left: rem(15);
    padding-right: rem(15);
    @include notlast {
      border-right: 1px solid currentColor; }

    &--current {
      color: $c-text; } }

  &__link {
    @include hover-focus {
      text-decoration: underline; } } }

.info-list {
  &__item {
    @include notlast {
      margin-bottom: rem(15); } }

  &__link {
    @include hover-focus {
      text-decoration: underline; }

    &--active {
      text-decoration: underline; } } }

.scroll-top {
  @include tr(color .3s);
  @include hover-focus {
    color: $light; }

  .icon {
    font-size: rem(54);
    fill: none;

    path {
      fill: currentColor; }

    circle {
      stroke: currentColor; } } }


.text-block {
  $this: &;

  &__title {
    @extend %text-title; }

  &__text {
    @extend %text;
    p, img {
      @include notlast {
        margin-bottom: rem(45);
        @include xl {
         margin-bottom: rem(28); }
        @include xs {
          margin-bottom: rem(20); } } } }

  &--two-cols {
    #{$this}__text {
      @include xlmin {
        column-count: 2;
        column-gap: rem(59); } } } }

.gallery-item {
  display: block;
  font-size: 0;
  padding-top: percentage(563 / 427);
  position: relative;
  img {
    @extend %coverimage;

    position: absolute;
    top: 0;
    left: 0; } }

.splitting {
  .word {
    white-space: nowrap;
    overflow: hidden;
    display: inline-block; }

  .char {
    display: inline-block; } }

.two-images {
  @extend %row;

  margin-left: rem(-8);
  margin-right: rem(-8);

  @include md {
    margin-left: rem(-4);
    margin-right: rem(-4); }

  &__col {
    margin-left: rem(8);
    margin-right: rem(8);

    width: calc(50% - #{rem(16)});

    @include md {
      margin-left: rem(4);
      margin-right: rem(4);
      width: calc(50% - #{rem(8)}); } } }

.close {
  @include icon-close(1.5em, .1em, currentColor);
  @include tr(color .3s);
  @include hover-focus {
    color: $light; }

  &--lg {
    @include icon-close(2.5em, .15em, currentColor); } }
