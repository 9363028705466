strong {
  font-weight: 600; }

.title {
  @include f-title;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.02em;

  &--h1 {
    @include css-lock(36, 117, 479, $base-desktop, font-size);

    &.splitting {
      letter-spacing: .01em;
      @include css-lock(30, 100, 479, $base-desktop, line-height); } }

  &--h2 {
    @include css-lock(36, 108, 479, $base-desktop, font-size);
    &.splitting {
      @include css-lock(31, 94, 479, $base-desktop, line-height); } }

  &--h3 {
    @include css-lock(36, 70, 479, $base-desktop, font-size); }

  &--h3-sm {
    @include css-lock(28, 60, 479, $base-desktop, font-size); }

  &--h4 {
    @include css-lock(33, 47, 479, $base-desktop, font-size); }

  &--h5 {
    @include css-lock(28, 38, 479, $base-desktop, font-size); }

  &--h5-sm {
    font-size: rem(28); }

  &--ttn {
    text-transform: none; } }

.link {
  display: inline-block;
  letter-spacing: .02em;
  padding-bottom: .1em;
  position: relative;
  @include tr(color .3s);

  @include before {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-top: 1px solid $light; }

  @include hover-focus {
    color: $light; } }

.link-with-icon {
  @extend %text-title;

  margin-bottom: 0;
  color: inherit;

  display: inline-flex;
  align-items: center;

  .icon {
    margin-right: .7em; }

  @include hover-focus {
    text-decoration: underline; } }

.list {
  &__item {
    @include notlast {
      margin-bottom: rem(30); } }

  &__title {
    display: flex;
    align-items: center;

    @include f-title;
    font-weight: 500;
    font-size: rem(28);
    line-height: 1;

    margin-bottom: rem(20);

    @include lg {
      font-size: rem(24); }

    @include before {
      @extend %romb;

      margin-right: rem(25);

      width: rem(8);
      height: rem(8); } } }

.meta-list {
  $this: &;

  @extend %text-title;
  @extend %clr;

  font-weight: 400;
  color: inherit;
  text-transform: none;
  margin-bottom: rem(-19);

  @include xs {
    font-size: em(13); }

  &__title,
  &__text {
    margin-bottom: rem(19);
    @include xs {
      margin-bottom: rem(10); } }

  &__title {
    float: left; }

  &__text {
    text-align: right;

    &[type="text"] {
      background-color: transparent;
      border: 0;
      display: block;
      float: right;
      min-width: rem(160); } }

  &--strong-combine {
    font-weight: 600;
    #{$this}__text {
      color: $light; } }

  &--strong {
    font-weight: 600; } }
