.links-block {
  &__inner {
    @extend %row; }

  &__left {
    width: percentage(5 / 12);
    padding-right: rem(20);
    position: relative;

    @include xl {
      padding-right: rem(15); }

    @include lg {
      width: 50%;
      padding-right: rem(5); }

    @include md {
      width: vw(334, $base-mobile);
      height: vw(440, $base-mobile);

      padding-right: 0;
      margin-bottom: rem(30);
      margin-left: auto;
      margin-right: auto; }

    .image {
      @extend %coverimage;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - #{rem(20)});
      @include xl {
        width: calc(100% - #{rem(15)}); }
      @include lg {
        width: calc(100% - #{rem(5)}); }
      @include md {
        width: 100%; } } }

  &__right {
    width: percentage(7 / 12);
    padding-left: rem(20);

    @include xl {
      padding-left: rem(15); }

    @include lg {
      width: 50%;
      padding-left: rem(5); }

    @include md {
      width: 100%;
      padding-left: 0; } } }

.links {
  display: grid;
  grid-gap: rem(40);
  grid-template-columns: repeat(3, 1fr);

  @include xl {
    grid-gap: rem(30); }

  @include lg {
    grid-gap: rem(10); }

  @include sm {
    display: block; }

  &__item {
    @include sm {
      @include notlast {
        margin-bottom: rem(30); }

      &:nth-child(2),
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(9) {
        .link-card {
          &__img {
            order: 1; }
          &__content {
            text-align: left; } } } }

    &--info {
      @extend %flex-center;
      text-align: center;
      flex-direction: column;

      @include sm {
        display: none; } } }

  &__title {
    @extend %text-title;

    max-width: rem(150);
    margin-left: auto;
    margin-right: auto; }

  &__link {
    height: 100%;
    min-height: rem(263);

    @include xl {
      min-height: rem(192); } } }

.link-card {
  $this: &;

  display: block;
  position: relative;
  background-color: $lighten;
  text-align: center;

  @include sm {
    display: flex;
    flex-wrap: wrap;
    background-color: transparent; }

  @include hover {
    #{$this}__content {
      @extend %visible; }

    #{$this}__img {
      @extend %hidden; }

    #{$this}__title,
    #{$this}__text,
    #{$this}__price {
      span {
        transform: translate(0, 0);
        opacity: 1; } } }

  &__content {
    display: block;
    padding: rem(60) rem(10) rem(45);
    @include ready {
      @include tr(opacity .4s, visibility .4s); }

    @include smmin {
      @include hidden; }

    @include xl {
      padding: rem(40) rem(10) rem(25); }

    @include sm {
      width: 50%;
      text-align: right; } }

  &__img {
    @extend %vcenter;

    display: block;
    width: 100%;
    height: 100%;

    overflow: hidden;

    img {
      @extend %vcenter;
      height: percentage(203 / 263); }

    @include sm {
      transform: none;
      position: relative;
      left: auto;
      top: auto;
      width: 50%;
      height: auto;
      background-color: $lighten; }


    @include ready {
      @include tr(opacity .4s, visibility .4s); } }

  &__title,
  &__text,
  &__price {
    overflow: hidden;
    span {
      display: block;
      @include smmin {
        transform: translate(0, 30px); }
      @include ready {
        transition-property: opacity, transform;
        transition-duration: .3s, .4s; } } }


  &__title {
    @extend %text-title;
    margin-bottom: rem(15);
    @include xl {
      margin-bottom: rem(8); } }

  &__text {
    display: block;
    margin-bottom: rem(40);
    max-width: rem(160);
    letter-spacing: .02em;

    margin-left: auto;
    margin-right: auto;

    @include xl {
      margin-bottom: rem(30); }

    @include sm {
      max-width: none;
      margin-left: 0;
      margin-right: 0; }

    span {
      transition-delay: .05s; } }

  &__price {
    @extend %text-title;

    margin-bottom: 0;

    span {
      transition-delay: .1s; } }

  &__title,
  &__price {
    display: block;
    color: inherit;
    font-weight: 600; }

  &--lg {
    display: flex;
    flex-direction: column;
    padding-top: rem(45);

    @include sm {
      padding-top: 0;
      flex-direction: row; }

    #{$this}__title {
      color: $light;
      margin-bottom: rem(7); }

    #{$this}__img {
      display: block;
      position: relative;
      top: auto;
      left: auto;
      transform: none;

      // padding-top: 85%
      height: auto;
      flex-grow: 1;

      @include sm {
        padding-top: 0; }

      img {
        @extend %vcenter;
        height: 88%; } }

    #{$this}__footer {
      @extend %row;

      text-align: left;
      justify-content: space-between;
      margin-top: auto;

      padding: rem(25);

      @include sm {
        flex-direction: column;
        justify-content: center;

        width: 50%;
        min-height: vw(185, $base-mobile);

        text-align: right;
        padding: rem(35) 0; } }

    #{$this}__text,
    #{$this}__title,
    #{$this}__price {
      span {
        transform: none; } }

    #{$this}__text {
      @extend %text-title;

      max-width: 80%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;

      color: inherit;
      font-weight: 600;

      @include sm {
        color: rgba($c-text, .7);
        font-size: 1em;
        text-transform: none;
        font-weight: inherit;
        margin-bottom: rem(12);
        max-width: none; } }

    #{$this}__title {
      width: 100%;
      @include sm {
        margin-bottom: rem(12); } }

    #{$this}__price {
      display: flex;
      flex-direction: column;
      justify-content: flex-end; }

    #{$this}__content {
      @extend %coverdiv;
      z-index: 1;

      display: flex;
      flex-direction: column;

      background-color: $lighten;

      padding: rem(70) rem(55) rem(35);

      @include xl {
        padding: rem(70) rem(20) rem(35); }

      @include sm {
        display: none; }

      > span {
        &:nth-child(1) {}
        &:nth-child(2) {
          margin-bottom: rem(25); }
        &:nth-child(4) {
          margin-top: auto;
          margin-bottom: auto;
          transform: translate(0, #{rem(-30)}); }
        &:nth-child(5) {
          #{$this}__text {
            // margin-bottom: vw(12)
            // +xxxlmin
 } }            //   margin-bottom: rem(12)
        &:nth-child(6) {
 } }          // margin-top: 0



      #{$this}__text {
        max-width: none;
        // margin-bottom: vw(35)
        // +xxxlmin
 }        //   margin-bottom: rem(35)

      #{$this}__title {
        // margin-bottom: vw(12)
        // +xxxlmin
 }        //   margin-bottom: rem(12)

      #{$this}__btn {
        // margin-top: vw(39)
        // +xxxlmin
 } } } }        //   margin-top: rem(39)
