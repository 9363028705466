.contacts {
  font-size: 0;
  position: relative;

  &__block {
    width: 50%;
    display: inline-block;
    font-size: 1rem;
    margin-bottom: rem(60);

    @include md {
      margin-bottom: rem(40); }

    @include xs {
      width: 100%;
      padding-right: 0;
      margin-bottom: rem(40); }

    &:nth-child(odd) {
      padding-right: rem(59); }

    &:nth-child(5),
    &:nth-child(6) {
      display: inline-flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 0;

      margin-top: auto;

      position: absolute;
      bottom: 0;

      @include md {
        position: static; } }

    &:nth-child(5) {
      left: 0;
      @include xs {
        margin-bottom: rem(33, $base-fz-sm); } }

    &:nth-child(6) {
      right: 0; } } }

.contacts-block {
  &__title {
    @extend %text-title;

    font-weight: 600;
    color: inherit; } }

