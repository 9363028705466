.out {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  &__header {
    position: fixed;
    z-index: $z-index-controls;
    top: 0;
    left: 0;
    right: 0;
    height: $header-height;
    background-color: $white;

    @include lg {
      height: $header-height-lg; }

    > .container {
      height: 100%; }

    &--animated {
      opacity: 0; } }

  &__main {
    padding-top: $header-height;
    padding-left: $main-gap;
    padding-right: $main-gap;
    flex-grow: 1;

    @include lg {
      padding-top: $header-height-lg; } }

  &__menu {
    @extend %hidden;

    padding-top: $header-height;

    @include lg {
      padding-top: $header-height-lg; }

    @include ready {
      @include tr(opacity .4s, visibility .4s); }

    &--open {
      @extend %visible; } }

  &__footer {} }

