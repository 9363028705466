.form {
  $this: &;
  $input-sm-width: calc(#{rem(178)} - #{rem(20)});
  $input-md-width: calc(100% - #{rem(178 * 2)} - #{rem(20)});

  &__title {
    margin-bottom: rem(100);
    @include xl {
      margin-bottom: rem(75); }
    @include lg {
      margin-bottom: rem(65); }
    @include md {
      margin-bottom: rem(45); } }

  &__field,
  &__row {
    @include notlast {
      margin-bottom: rem(25);
      @include md {
        margin-bottom: rem(12); } } }

  &__field {
    &--combine {
      @extend %row;

      margin-left: rem(-10);
      margin-right: rem(-10); }

    &--combine-3-items {
      @extend #{$this}__field--combine;

      @include xs {
        #{$this}__input--sm,
        #{$this}__input--md {
          width: calc(#{percentage(1 / 3)} - #{rem(20)}); } } } }

  &__input {
    &--sm {
      margin-left: rem(10);
      margin-right: rem(10);
      width: $input-sm-width;

      @include xs {
        width: calc(50% - #{rem(20)}); } }

    &--md {
      margin-left: rem(10);
      margin-right: rem(10);
      width: $input-md-width;

      @include xs {
        width: 100%;
        margin-bottom: rem(12); }

      &.btn-weird {
        @include lg {
          text-align: left;
          justify-content: flex-start; }
        @include sm {
          width: 100%;
          margin-top: rem(12); } } } }

  &__btn {
    margin-top: rem(100);
    @include xl {
      margin-top: rem(80); }
    @include lg {
      margin-top: rem(70); }
    @include md {
      margin-top: rem(50); }

    &--mt-sm {
      margin-top: rem(25); } }

  &__row {
    @extend %row;

    margin-left: rem(-10);
    margin-right: rem(-10); }

  &__col {
    width: 50%;
    padding-left: rem(10);
    padding-right: rem(10);
    margin-bottom: rem(20);

    &--50 {
      width: 50% !important; }

    @include xs {
      width: 100%; } }

  &__block-title {
    @extend %text-title;

    margin-bottom: rem(25);
    color: inherit;

    @include sm {
      margin-bottom: rem(12, $base-fz-sm); } }

  &__block {
    margin-bottom: rem(65);

    @include sm {
      margin-bottom: rem(50, $base-fz-sm); }

    &--hidden {
      @extend %hard-hidden; } }

  &--lg-gaps {
    #{$this}__field {
      @include notlast {
        margin-bottom: rem(55);
        @include md {
          margin-bottom: rem(30); } }

      &--combine {
        #{$this}__input--md {
          @include xs {
            margin-bottom: rem(30); } } } }

    #{$this}__col {
      margin-bottom: rem(55);
      @include md {
        margin-bottom: rem(30, $base-fz-sm); } }

    #{$this}__row {
      margin-bottom: 0; } } }


.sign-form {
  $this: &;

  text-align: left;
  padding: rem(35) rem(60) rem(60);

  @include md {
    padding: rem(35) rem(40) rem(40); }

  @include sm {
    padding: rem(35) rem(25) rem(25); }

  %text {
    font-size: em(16);
    line-height: (23 / 16);
    letter-spacing: .02em;
    font-weight: 500; }

  &__link {
    @extend %text;

    display: inline-block;

    text-decoration: underline;


    margin-bottom: rem(55); }

  &__title {
    margin-bottom: rem(20); }

  &__subttl {
    @extend %text;

    color: $light;

    margin-bottom: rem(55); }

  &--center {
    padding-top: rem(114);
    padding-bottom: rem(114);

    text-align: center;

    #{$this}__title {
      margin-bottom: rem(55); } }

  &--center-top {
    @extend #{$this}--center;
    padding-bottom: rem(55);

    #{$this}__title {
      margin-bottom: rem(109); } } }

