.dropdown {
  $this: &;

  position: relative;

  &__title {
    @include text-overflow;
    width: 100%;
    text-align: left; }

  &__block {
    @include notlast {
      margin-bottom: rem(20); } }

  &--full {
    #{$this}__panel {
      @include lg {
        padding-top: 0 !important;
        padding-bottom: 0 !important;

        max-height: 0;
        overflow: hidden;
        @include ready {
          @include tr(max-height .4s); }

        .nav__item {
          &:first-child {
            margin-top: rem(40); }
          &:last-child {
            margin-bottom: rem(40); } } } } }

  &--aside {
    #{$this}__title {
      @extend %text-title;

      @include lg {
        margin-bottom: 0;
        color: inherit;

        border-bottom: 1px solid currentColor;
        padding: rem(8) 0;
        height: rem(39);

        position: relative; }

      @include before {
        @extend %select-romb;
        display: none;

        @include lg {
          display: block; } } }

    &:not(#{$this}--full) {
      #{$this}__panel {
        @include lg {
          position: absolute;
          z-index: 3;

          top: calc(100% + 22px);
          left: 0;

          border: 1px solid currentColor;
          background-color: $white;

          padding: rem(25);

          @include hidden; } } } }

  &--open {
    #{$this}__title {
      @include before {
        transform: translate(0, -50%) rotate(45deg); } } } }
