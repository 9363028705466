select {
  &.js-select {
    opacity: 0; } }

.input {
  $this: &;

  position: relative;
  display: block;

  #{$text-inputs} {
    @extend %text-title;

    margin-bottom: 0;
    color: inherit;
    font-weight: 600;
    text-transform: none;

    width: 100%;
    border-bottom: 1px solid $light;
    height: rem(40);

    padding: .5rem 0; }

  textarea {
    padding: .9rem 0; }

  &__label {
    @extend %text-title;

    margin-bottom: 0;
    font-weight: 600;

    position: absolute;
    left: 0;
    top: rem(8);

    pointer-events: none;

    @include tr(transform .4s); }

  &:not(#{$this}--checkbox):not(#{$this}--checkbox-btn):not(#{$this}--radio):not(#{$this}--picker) {
    #{$this}__label {
      @include text-overflow;
      max-width: 100%; } }

  &--focus,
  &--has-text {
    #{$this}__label {
      transform: translate(-15%, #{rem(-22)}) scale(.7);
      max-width: 140%; } }

  &--checkbox-btn {
    position: relative;
    display: block !important;
    padding-left: 0 !important;
    cursor: pointer;

    @include hover-focus {
      #{$this}__label {
        background-color: $black;
        color: $white; } }

    #{$check-inputs} {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + #{$this}__label {
        background-color: $black;
        color: $white; } }

    #{$this}__label {
      @extend .btn;
      @extend .btn--transp-mod;
      @extend .btn--full;

      position: static;
      padding: rem(14) rem(15) !important; } }

  &--checkbox,
  &--radio {
    user-select: none;
    padding-left: rem(35);
    padding-top: rem(4);
    padding-bottom: rem(4);

    display: inline-block;
    cursor: pointer;

    @include xs {
      padding-left: rem(25); }

    #{$check-inputs} {
      width: rem(18);
      height: rem(18);

      border: rem(3) solid $white;

      box-shadow: 0 0 0 rem(1) $light;

      position: absolute;
      top: rem(6);
      left: 0;

      appearance: none;
      cursor: pointer;

      @include xs {
        width: rem(14);
        height: rem(14);
        border-width: rem(2);
        top: rem(8); }

      &:checked {
        background-color: $black; } }

    #{$this}__label {
      position: static;

      text-transform: none;
      font-size: inherit;
      font-weight: inherit;
      color: inherit;
      letter-spacing: .02em; } }

  &--radio {
    #{$this}__label {
      text-transform: uppercase;
      font-weight: 600; } }

  &--picker {
    color: $light;

    &#{$this}--has-text {
      color: inherit;
      @include before {
        transform: translate(0, -50%) rotate(45deg); } }

    @include before {
      @extend %select-romb; }

    #{$text-inputs} {
      text-transform: uppercase;
      padding-right: rem(15);
      @include text-overflow; } } }

.custom-select {
  $this: &;

  position: relative;
  color: $light;

  select {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0; }

  &__opener {
    @extend .filter-block__title;
    @extend %text-title;

    border-bottom: 1px solid currentColor !important;

    cursor: pointer;
    color: inherit;

    font-weight: 600 !important;
    text-transform: uppercase !important;
    letter-spacing: .14em !important;
    font-size: em(15) !important;

    @include xs {
      font-size: 1em !important; } }

  &__panel {
    @extend .filter-block__panel;
    @extend %hidden;

    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    z-index: 5;

    background-color: $white;

    max-height: rem(275);

    @include ready {
      @include tr(opacity .3s, visibility .3s); } }

  &__option {
    @extend .filter-block__link;
    cursor: pointer;

    &[data-value="placeholder"] {
      display: none; }

    &--selected {
      @extend .filter-block__link--current; } }

  &--open,
  &--has-text {
    #{$this}__opener {
      @include before {
        transform: translate(0, -50%) rotate(45deg); } } }

  &--open {
    color: inherit;

    #{$this}__panel {
      @extend %visible; } }

  &--has-text {
    color: inherit; } }
