.fancy-link {
  $this: &;

  --circle-size:    #{rem(164)};
  --circle-size-sm: #{rem(120)};

  $circle-size:    var(--circle-size);
  $circle-size-sm: var(--circle-size-sm);

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: left;

  position: relative;
  min-width: $circle-size;
  height: $circle-size;
  padding-right: rem(78);

  width: rem(217);

  @include xl {
    --circle-size: #{rem(115)};
    --circle-size-sm: #{rem(92)};

    padding-right: rem(59);
    width: rem(195); }

  @include md {
    width: rem(150); }

  @include sm {
    --circle-size-sm: #{rem(80)}; }

  @include xs {
    --circle-size-sm: #{rem(60)}; }

  &__title {
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: block; }

  &__title-in {
    display: inline-block; }

  &__img {
    position: absolute;

    z-index: 0;
    top: 0;
    right: 0;

    font-size: 0;

    .icon {
      fill: none;
      stroke: $light;
      font-size: var(--circle-size);
      path {
        transform: translate(10px, 10px); } } }

  &--play {
    min-width: $circle-size-sm;
    width: auto !important;
    height: $circle-size-sm;
    color: $white;
    padding-right: 0;

    @include before {
      @extend %vcenter;
      margin-left: .1em;
      @include arr(1.2em, 1.4em, currentColor, r); }

    #{$this}__img {
      .icon {
        font-size: var(--circle-size-sm); } } } }
