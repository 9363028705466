.item-card {
  @extend %row;

  &__img {
    width: percentage(688 / $base-container);
    .swiper-container {
      background-color: $lighten; }

    @include lg {
      width: 50%;
      padding-right: rem(15); }

    @include md {
      width: 100%;
      padding-right: 0;
      margin-bottom: rem(40); }

    .slider__wrap,
    .swiper-container,
    .swiper-wrapper,
    .swiper-slide {
      height: 100%;

      @include md {
        height: auto; } } }

  &__content {
    width: percentage(794 / $base-container);
    margin-left: percentage(($base-container - 794 - 688) / $base-container);
    max-width: rem(550);

    @include lg {
      margin-left: 0;
      padding-left: rem(15); }

    @include md {
      width: 100%;
      max-width: none;
      padding-left: 0; } }

  &__upttl {
    @extend %text-title;
    color: inherit;
    margin-bottom: rem(23); }

  &__title {
    margin-bottom: rem(26); }

  &__text {
    @extend %text;

    margin-bottom: rem(45); }

  &__list {
    margin-bottom: rem(160);
    @include xl {
      margin-bottom: rem(100); }
    @include lg {
      margin-bottom: rem(80); }
    @include md {
      margin-bottom: rem(50); } } }

.item-list {
  @extend %text-title;

  color: inherit;

  &__item {
    display: flex;
    align-items: center;

    @include xs {
      display: block; }

    > span {
      display: block;

      &:first-child {
        @include xs {
          margin-bottom: rem(10); } }

      &:last-child {
        margin-left: auto;
        @include xs {
          font-size: inherit;
          text-transform: none;
          letter-spacing: initial;
          font-weight: inherit; } } }

    @include notlast {
      margin-bottom: rem(30);

      @include xs {
        margin-bottom: rem(24); } }

    @include before {
      @extend %romb;

      width: rem(6);
      height: rem(6);

      margin-right: rem(15);

      @include xs {
        display: none; } } } }

.item-form {
  &__field {
    @extend %row;

    border-bottom: 1px solid $black;
    height: rem(60);

    &:first-child {
      border-top: 1px solid $black; } }

  &__left,
  &__right {
    @extend %text-title;

    margin-bottom: 0;
    color: inherit;
    font-weight: 600; }

  &__left {
    display: flex;
    align-items: center;
    border-right: 1px solid $black;
    height: 100%;

    width: percentage((550 - 112) / 550); }

  &__right {
    @extend %flex-center;

    height: 100%;
    width: percentage(112 / 550); }

  &__btn {
    margin-top: rem(25);

    @include md {
      position: fixed;
      background-color: $white;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $z-index-controls;

      padding-left: $base-gap;
      padding-right: $base-gap;
      padding-bottom: rem(15); } } }

