.posts {
  @extend %row;
  margin-left: rem(-20);
  margin-right: rem(-20);

  @include xl {
    margin-left: rem(-15);
    margin-right: rem(-15); }

  &__item {
    width: 50%;
    padding-left: rem(20);
    padding-right: rem(20);
    margin-bottom: rem(80);

    @include xl {
      padding-left: rem(15);
      padding-right: rem(15); }

    @include md {
      width: 100%;
      margin-bottom: rem(35); }

    &--full {
      width: 100%; } } }

.post {
  $this: &;

  display: block;

  @include hover-focus {
    #{$this}__title {
      text-decoration: underline; } }

  &__img {
    display: block;
    padding-top: percentage(460 / 690);
    background-color: $light;
    position: relative;

    @include md {
      padding-top: percentage(194 / 334); }

    img,
    .image {
      @extend %coverimage;
      position: absolute;
      top: 0;
      left: 0; } }

  &__content {
    @extend %row;
    padding-top: rem(18); }

  &__date {
    display: block;
    width: percentage(1.1 / 6);
    @include css-lock(5, 18, 991, $base-desktop, padding-top);

    font-size: em(15);
    letter-spacing: .14em;
    text-transform: uppercase;

    @include xl {
      font-size: 1em;
      width: rem(150); }

    @include lg {
      width: rem(120); }

    @include sm {
      font-size: em(12, $base-fz-sm);
      width: rem(100); } }

  &__title {
    display: block;
    @include f-title;
    width: percentage(4.9 / 6);
    font-weight: 500;
    @include css-lock(25, 47, 991, $base-desktop, font-size);
    line-height: 1;
    letter-spacing: .02em;

    @include xl {
      width: calc(100% - #{rem(150)}); }

    @include lg {
      width: calc(100% - #{rem(120)}); }

    @include sm {
      width: calc(100% - #{rem(100)}); }

    @include r(413) {
      font-size: em(21, $base-fz-sm); } }

  &--lg {
    #{$this}__img {
      padding-top: percentage(706/ $base-container);
      @include md {
        padding-top: percentage(194 / 334); } }

    #{$this}__date {
      font-size: em(17);
      width: percentage(($base-container - 1345) / $base-container);

      @include css-lock(50, 65, 1399, $base-desktop, padding-top);

      @include xl {
        @include css-lock(0, 50, 991, 1399, padding-top);
        font-size: em(17); }

      @include md {
        font-size: 1em;
        padding-top: 5px; }

      @include sm {
        font-size: em(12, $base-fz-sm); } }

    #{$this}__title {
      width: percentage(1345 / $base-container);

      @include css-lock(90, 108, 1399, $base-desktop, font-size);
      @include xl {
        @include css-lock(25, 90, 991, 1399, font-size); } } } }

