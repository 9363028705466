.popup {
  $this: &;

  @extend %overlay;
  @extend %hidden;

  z-index: $z-index-modal;

  background-color: rgba($black, .58);

  padding: rem(15);
  overflow: auto;

  @include ready {
    @include tr(opacity .4s, visibility .4s); }

  &:not(#{$this}--full) {
    @include vertical; }

  &__inner {
    display: inline-block;
    vertical-align: middle;

    width: 100%;
    max-width: rem(700);

    background-color: $white;
    font-size: 1rem;
    position: relative;

    > .container {
      position: relative; } }

  &__close {
    position: absolute;
    z-index: 1;
    top: rem(23);
    right: rem(23); }

  &--full {
    padding: 0;
    overflow: hidden;

    #{$this}__inner {
      height: 100%;
      max-width: none;
      overflow: auto;

      @include vertical;

      > .container {
        display: inline-block;
        vertical-align: middle;
        width: 100%;
        font-size: 1rem;

        &:first-child {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, 0); } } }

    #{$this}__close {
      top: rem(55);
      right: rem(15); } }

  &--banner {
    #{$this}__inner {
      max-width: rem(1200); }

    #{$this}__img {
      width: 100%; } }

  &--active {
    @extend %visible; } }
