@include font('montserrat', 'montserrat-italic-webfont', 400, italic);
@include font('montserrat', 'montserrat-regular-webfont', 400);
@include font('montserrat', 'montserrat-semibold-webfont', 600);

@include font('cormorantgaramond', 'cormorantgaramond-medium-webfont', 500);

@mixin reg {
  font-family: 'montserrat', sans-serif; }

@mixin f-title {
  font-family: 'cormorantgaramond', serif; }

