.btn {
  $this: &;

  @extend %text-title;

  margin-bottom: 0;
  font-weight: 600;

  display: inline-block;

  text-align: center;
  text-transform: uppercase;

  background-color: $black;
  border: 1px solid  $black;

  color: $white;

  min-width: rem(250);

  padding: rem(19) rem(15);

  @include tr(background-color .3s, color .3s, border-color .3s);

  @include hover-focus {
    &:not(#{$this}--no-hover):not(#{$this}--disabled) {
      background-color: transparent;
      color: $black; } }

  &--full {
    min-width: 0;
    width: 100%; }

  &--transp {
    background-color: transparent;
    border-right: 0;
    border-left: 0;

    color: $black;

    padding: rem(14) rem(15); }

  &--transp-mod {
    background-color: transparent;

    color: $black;

    @include hover-focus {
      &:not(#{$this}--no-hover):not(#{$this}--disabled) {
        background-color: $black;
        color: $white; } } }

  &--lg {
    min-width: rem(408);
    @include xs {
      min-width: 0;
      width: 100%; } }

  &--md {
    min-width: rem(356);
    @include xs {
      min-width: 0;
      width: 100%; } }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed; } }

.btn-more {
  @extend %romb;

  position: relative;

  width: rem(47);
  height: rem(47);

  color: $white;

  @include tr(transform .4s $easeOutQuad);


  @include hover-focus {
    transform: rotate(45deg) scale(1.1); }

  &::before,
  &::after {
    content: '';

    @extend %vcenter;

    background-color: currentColor;

    transform: translate(-50%, -50%) rotate(45deg); }


  &::before {
    height: 2px;
    width: rem(14); }

  &::after {
    width: 2px;
    height: rem(14); } }

.two-buttons {
  $this: &;

  @extend %row;
  justify-content: center;
  align-items: center;

  margin-left: rem(-20);
  margin-right: rem(-20);

  &__btn {
    width: rem(110);
    margin-left: rem(20);
    margin-right: rem(20); }



  &--lg {
    #{$this}__btn {
      width: auto;
      @include sm {
        width: 100%;
        @include notlast {
          margin-bottom: rem(25); } } } } }

.btn-weird {
  @extend %text-title;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-weight: 600;
  margin-bottom: 0;

  height: rem(50);

  @include tr(color .3s);

  @include hover-focus {
    color: $c-text; }

  @include before {
    @extend %romb;

    background-color: currentColor;

    width: rem(7);
    height: rem(7);

    margin-right: .5em; } }

.btn-icon {
  display: inline-block;
  font-size: 0;
  .icon {
    font-size: 1.2rem; } }
