%overlay {
  @include overlay; }

%coverdiv {
  @include coverdiv; }

%cover {
  @include cover; }

%clr {
  @include clr; }

%hidden {
  @include hidden; }

%visible {
  @include visible; }

%vcenter {
  @include vcenter; }

%text-ovdrflow {
  @include text-overflow; }

%row {
  display: flex;
  flex-wrap: wrap; }

%coverimage {
  width: 100%;
  height: 100%;
  object-fit: cover; }

%flex-center {
  display: flex;
  align-items: center;
  justify-content: center; }

%text-title {
  text-transform: uppercase;
  font-size: em(15);
  line-height: (25 / 15);
  font-weight: 500;
  letter-spacing: .14em;
  color: $light;
  margin-bottom: rem(20);
  @include xl {
    font-size: 1em; }
  @include xs {
    margin-bottom: rem(12, $base-fz-sm); } }

%section-padding {
  padding-top: rem(110);
  padding-bottom: rem(110);
  @include xl {
    padding-top: rem(75);
    padding-bottom: rem(75); }
  @include md {
    padding-top: rem(60);
    padding-bottom: rem(60); }
  @include xs {
    padding-top: rem(45);
    padding-bottom: rem(45); } }

%romb {
  display: inline-block;

  width: rem(15);
  height: rem(15);

  background-color: $c-text;
  transform: rotate(45deg); }

%select-romb {
  display: inline-block;
  background-color: currentColor;

  width: 6px;
  height: 6px;

  position: absolute;
  z-index: 1;
  top: 50%;
  right: 2px;

  transform: translate(0, -50%) rotate(0);

  @include tr(transform .4s); }

%text {
  line-height: (29 / 14);
  p {
    @include notlast {
      margin-bottom: rem(30); } } }

%full-section {
  margin-left: rem(-25);
  margin-right: rem(-25);
  padding-left: rem(25);
  padding-right: rem(25); }

%hard-hidden {
  display: none; }

