.overflow-hidden {
  overflow: hidden; }

.pt-0 {
  padding-top: 0 !important; }

.pb-0 {
  padding-bottom: 0 !important;

  &--lg {
    @include lgmin {
      padding-bottom: 0 !important; } } }

.text-center {
  text-align: center;

  &--md {
    @include mdmin {
      text-align: center; } } }

.pointer-events-none {
  pointer-events: none; }
