.nav {
  $this: &;

  &__item {
    display: inline-block;
    @include notlast {
      margin-right: rem(58);

      @include lg {
        margin-right: 0; } }

    &--current {
      #{$this}__link {
        font-weight: 600;
        @include before {
          transform: translate(0, 0); } } } }

  &__link {
    position: relative;
    display: inline-block;
    overflow: hidden;

    @include before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid currentColor;
      transform: translate(-105%, 0);
      @include tr(transform .4s $easeOutQuad); }

    @include hover-focus {
      @include before {
        transform: translate(0, 0); } } }

  &--vert {
    #{$this}__list {
      @extend %row; }

    #{$this}__item {
      width: percentage(2 / 5);
      padding-right: rem(15);

      @include lg {
        width: 50%; }

      @include notlast {
        margin-right: 0;
        margin-bottom: rem(35);
        @include lg {
          margin-bottom: rem(25); } } } }

  &--vert-lg {
    @extend %text-title;

    margin-bottom: 0;
    color: inherit;

    #{$this}__item {
      display: block;
      @include notlast {
        margin-bottom: rem(10);
        margin-right: 0; }

      &--current {
        font-weight: 600;
        text-decoration: none; } } }

  &--center {
    #{$this}__item {
      margin-left: rem(55);
      margin-right: rem(55);

      @include sm {
        margin-left: 0;
        margin-right: 0;
        display: block; }

      @include notlast {
        margin-right: rem(55);
        @include sm {
          margin-right: 0;
          margin-bottom: rem(30, $base-fz-sm); } } } }

  &--md {
    @extend %text-title;

    margin-bottom: 0;
    font-weight: 600;
    color: inherit; }

  &--lg {
    @extend .title;
    @extend .title--ttn;
    @extend .title--h5;

    #{$this}__list {
      margin-bottom: rem(100); }

    #{$this}__item {
      text-decoration: none;
      display: block;
      @include notlast {
        margin-bottom: rem(35);
        margin-right: 0; }

      &--current {
        #{$this}__link {
          opacity: 1; } } }


    #{$this}__link {
      @include tr(opacity .3s);
      opacity: .6;

      @include hover-focus {
        opacity: 1;
        text-decoration: none; } } }

  &--xl {
    @include f-title;
    @include css-lock(26, 70, 479, $base-desktop, font-size);
    @include css-lock(800, 1310, 479, $base-desktop, max-width);

    font-weight: 500;
    line-height: (85 / 70);
    letter-spacing: .02em;
    text-transform: uppercase;

    margin-left: auto;
    margin-right: auto;

    @include lg {
      text-align: center;
      max-width: none; }

    #{$this}__list {
      @extend %row;
      justify-content: center;

      @include lg {
        display: block; } }

    #{$this}__item {
      margin-bottom: rem(45);
      margin-left: rem(45);
      margin-right: rem(45);
      position: relative;
      display: block;

      display: flex;
      align-items: center;

      @include lg {
        display: block;
        margin-bottom: rem(30);
        margin-left: 0;
        margin-right: 0; }

      @include xs {
        margin-bottom: rem(20); }

      @include notlast {
        margin-right: 0;
        margin-right: rem(45);
        @include lg {
          margin-right: 0; } }

      // &:nth-child(1),
      // &:nth-child(3),
      // &:nth-child(4),
      // &:nth-child(6)
      //   +after
      //     @extend %romb

      //     margin-left: rem(60)
      //     margin-right: rem(60)

      //     +lg
      //       display: none

      &--current {
        text-decoration: none;
        color: $light; }


      &--mob {
        margin-top: rem(90);
        margin-bottom: 0;
        @include lgmin {
          display: none; } } }

    #{$this}__link {
      @include tr(color .3s);

      @include before {
        display: none; }

      @include hover-focus {
        text-decoration: none;
        color: $light; } } } }
