.menu {
  @extend %overlay;

  z-index: 9;
  background-color: $white;

  display: flex;
  flex-direction: column;

  &__nav-wrap {
    display: flex;
    flex-direction: column;

    width: 100%;
    flex-grow: 1;
    overflow: auto;

    padding-bottom: rem(30);

    > .container {
      margin: auto; } }

  &__footer {
    border-top: 1px solid $black;

    @include md {
      display: none; } }

  &__footer-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-top: rem(55);
    padding-bottom: rem(55);

    a {
      @include hover-focus {
        text-decoration: underline; } } } }
