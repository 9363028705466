$base-fz:        14;
$base-fz-sm:     14;

$base-container: 1620;
$base-desktop:   1920;
$base-tablet-lg: 1280;
$base-mobile:    414;

$text-inputs: "[type='color']", "[type='date']", "[type='datetime']", "[type='datetime-local']", "[type='email']", "[type='month']", "[type='number']", "[type='password']", "[type='search']", "[type='tel']", "[type='text']", "[type='time']", "[type='url']", "[type='week']", "input:not([type])", "textarea";
$check-inputs: "[type='checkbox']", "[type='radio']";

$c-text: #000;

$black: #000;
$white: #fff;
$blue:  #00f;

$light:   #9E9E9E;
$lighten: #EFEFEF;
