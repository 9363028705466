.header {
  $this: &;

  @include lg {
    &__logo {
      order: 0; }
    &__lang {
      order: 1; }
    &__nav {
      order: 2;
      margin-left: auto; }
    &__search {
      order: 3; }
    &__burger {
      order: 4; } }

  &__logo,
  &__lang,
  &__nav,
  &__search,
  &__burger {
    flex-shrink: 0; }

  &__inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding-top: rem(10);
    padding-bottom: rem(10); }

  &__nav {
    width: 71%;
    padding-left: rem(15);
    padding-right: rem(15);

    text-align: right;

    @include lg {
      padding-left: 0;
      padding-right: 0;
      width: auto; }

    .nav__link {
      &:not(.header-cart) {
        @include lg {
          display: none; } } } }

  &__lang {
    margin-left: auto;

    @include lg {
      margin-left: rem(24); } }

  &__burger {
    margin-left: rem(45);
    @include lg {
      margin-left: rem(26); } }

  &__search {
    @extend %flex-center;
    margin-left: rem(65);

    @include lg {
      margin-left: rem(25); }

    @include tr(color .3s);

    @include hover-focus {
      color: $light; }

    .icon {
      font-size: rem(25); } } }

.burger {
  @extend %flex-center;

  width: rem(36);
  height: rem(24);

  position: relative;

  @include tr(color .3s);

  @include hover-focus {
    color: $light; }

  span {
    display: block;

    &, &::after, &::before {
      width: 100%;
      height: 2px;
      background-color: currentColor;
      backface-visibility: hidden;
      @include tr(transform .3s); }

    &::after, &::before {
      content: "";
      position: absolute;
      left: 0; }

    &::after {
      top: 0; }

    &::before {
      bottom: 0; } }

  &--active {
    span {
      background-color: transparent;
      &::before {
        transform: rotate(45deg) translate(-11px,-8px); }
      &::after {
        transform: rotate(-45deg) translate(-7px, 4px); } } } }

.header-cart {
  @include lg {
    font-size: 0; }

  span {
    @include lg {
      display: flex;
      align-items: center;
      justify-content: center;

      min-width: rem(28);
      height: rem(28);

      padding: rem(5);

      border-radius: rem(28);
      background-color: $c-text;
      color: $white;

      font-size: 1rem; } } }
