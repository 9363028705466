@mixin scrollbar($background: $white, $thumb-bg: $black) {
  &::-webkit-scrollbar {
    width: rem(7);
    height: rem(7); }
  &::-webkit-scrollbar-track {
    background-color: $background;
    outline: none; }
  &::-webkit-scrollbar-thumb {
    background-color: $thumb-bg;
    @include tr(background-color .3s); } }

.scrollbar {
  overflow: auto;
  @include scrollbar;

  &--sm {
    @include smmin {
      overflow: visible; } }

  &--light {
    @include scrollbar($white, $black);
    .simplebar-track {
      background-color: $white; }
    .simplebar-scrollbar {
      &::before {
        background-color: $white; } } } }

.simplebar-scrollbar {
  z-index: 1;
  &::before {
    opacity: 1 !important;
    background-color: $black;
    width: 100%;
    right: 0;
    left: 0;
    top: 0 !important;
    bottom: 0 !important;
    z-index: 1;
    border-radius: 0; } }

.simplebar-track {
  border-radius: 0;
  background-color: $white; }

.simplebar-track.simplebar-vertical {
  width: rem(7); }
