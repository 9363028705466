.article {
  font-size: em(15);
  line-height: (36 / 15);

  @include sm {
    font-size: 1em;
    line-height: (29 / 14); }

  > img {
    &:first-child:not(:last-child):not(.list__title):not(.list__item) {
      display: block;
      width: 100%;
      margin-bottom: rem(69);

      @include xl {
        margin-bottom: rem(55); }

      @include md {
        display: block;
        width: calc(100% + #{rem(80)});
        max-width: calc(100% + #{rem(80)});

        margin-left: rem(-40);
        margin-right: rem(-40);

        margin-bottom: rem(30); }

      @include sm {
        margin-bottom: rem(20); } } }

  h1, h2, h3, h4, h5, h6 {
    @extend .title;
    @extend .title--ttn;

    @include notlast {
      margin-bottom: rem(32);
      margin-top: rem(55);

      @include md {
        margin-bottom: rem(24);
        margin-top: rem(37); } } }

  ul,
  ol {
    &:not(:last-child):not(.list__title):not(.list__item) {
      margin-top: rem(49);
      margin-bottom: rem(55);

      @include xl {
        margin-top: rem(40);
        margin-bottom: rem(45); }

      @include sm {
        margin-top: rem(24, $base-fz-sm);
        margin-bottom: rem(37, $base-fz-sm); } }

    &:not(.list) {
      margin-left: 1.2em; } }

  h2 {
    &:not(.title) {
      @extend .title--h4; } }

  h3 {
    &:not(.title) {
      @extend .title--h5-sm; } }

  picture {
    display: block;
    font-size: 0; }

  p, img, picture, figure {
    &:not(:last-child):not(.list__title):not(.list__item) {
      margin-bottom: rem(22, $base-fz-sm);
      @include sm {
        margin-bottom: rem(18, $base-fz-sm); } } }

  ul {
    &:not(.list) {
      list-style-type: disc; } }

  ol {
    list-style-type: decimal; }

  a {
    color: $blue;
    @include hover-focus {
      text-decoration: underline; } }

  &__inner {
    max-width: rem(855);
    margin-left: auto;
    margin-right: auto;

    > img {
      display: block;
      width: 100%; } } }
