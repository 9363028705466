.breadcrumbs {
  $this: &;

  @extend %row;

  @include xs {
    font-size: em(11, $base-fz-sm); }

  &__item {
    display: flex;
    align-items: center;
    @include notlast {
      @include after {
        border-top: 1px solid currentColor;
        width: rem(16);

        margin-right: rem(15);
        margin-left: rem(15);

        @include xs {
          width: rem(9, $base-fz-sm);

          margin-left: rem(9, $base-fz-sm);
          margin-right: rem(9, $base-fz-sm); } } } }

  &__link {
    @extend .nav__link; }

  &--ttu {
    @extend %text-title;

    margin-bottom: 0;

    @include md {
      font-size: em(13); }
    @include sm {
      font-size: em(10); }

    #{$this}__item {
      @include notfirst {
        @include before {
          @include md {
            width: em(8); }
          @include sm {
            margin-left: em(5);
            margin-right: em(5); } } }
      &--active {
        color: $c-text; } } } }
