.filter-block {
  $this: &;

  position: relative;

  &:not(#{$this}--absolute) {
    #{$this}__panel {
      max-height: 0;
      overflow: hidden ! important;

      @include ready {
        @include tr(max-height .4s); } } }

  &__slider {
    margin: rem(35) rem(25) rem(60); }

  &__title {
    display: block;
    width: 100%;
    text-align: left;

    position: relative;
    z-index: 1;
    border-bottom: 1px solid transparent;

    padding: rem(10) rem(20) rem(10) 0;

    @include text-overflow;
    @include tr(border-color .3s);

    @include lg {
      height: rem(39);
      padding: rem(8) rem(20) rem(8) 0; }


    &:not(#{$this}__title--no-change) {
      @extend %text-title;

      margin-bottom: 0;
      color: inherit;

      @include lgmin {
        font-weight: inherit;
        font-size: inherit;
        text-transform: none;
        letter-spacing: inherit; } }

    @include before {
      @extend %select-romb; } }

  &__panel {
    background-color: $white;
    border-top: 0 !important;
    border: 1px solid $black; }

  &__item {
    &--current {
      #{$this}__link {
        pointer-events: none;
        @include before {
          display: block; } } } }

  &__link {
    display: flex;
    align-items: center;

    padding: rem(10) rem(18);
    @include tr(background-color .3s);

    &--current {
      font-weight: 500;
      @include before {
        display: block !important; } }

    @include hover-focus {
      background-color: $lighten; }

    @include before {
      @extend %romb;

      width: rem(6);
      height: rem(6);

      margin-right: rem(10);

      display: none; } }

  &--open {
    #{$this}__title {
      @include before {
        transform: translate(0, -50%) rotate(45deg); } }

    &:not(#{$this}--absolute) {
      #{$this}__title {
        border-bottom: 1px solid currentColor; }

      #{$this}__panel {
        max-height: rem(265); } }
    &:not(#{$this}--transitioning) {
      #{$this}__panel {
        overflow: auto !important; } } }

  &--absolute {
    &#{$this}--open {
      #{$this}__panel {
        @extend %visible; } }

    #{$this}__title {
      border-bottom-color: currentColor; }


    #{$this}__panel {
      @extend %hidden;

      position: absolute;
      z-index: 3;
      top: 100%;
      left: 0;
      width: 100%;

      @include ready {
        @include tr(opacity .4s, visibility .4s); } } } }
